@tailwind base;
@tailwind components;
@tailwind utilities;

.shadow-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-in;
}

.animate-fade-out {
  animation: fadeOut 0.3s ease-in;
}

.perspective {
  perspective: 1000px;
}

.preserve-3d {
  transform-style: preserve-3d;
}

@keyframes spinOut {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(90deg); }
}

@keyframes spinIn {
  0% { transform: rotateY(-90deg); }
  100% { transform: rotateY(0deg); }
}

.animate-spin-out {
  animation: spinOut 0.5s ease-in forwards;
}

.animate-spin-in {
  animation: spinIn 0.5s ease-out forwards;
}

.carousel-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.carousel.spinning {
  animation: spin 1s linear;
}

.carousel-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-face.back {
  transform: rotateY(180deg);
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@media (max-width: 640px) {
  .carousel-container {
    height: 60vh;
  }
}